import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import SearchResultsTableComponent from "@components/admin/items/search/SearchResultsTableComponent";
import SearchResultsPage from "@components/admin/SearchResultPage";
import { EsItemResponse } from "@reshopper/admin-client";

export default withAdminMenuLayout(function () {
    return <SearchResultsPage<EsItemResponse>
        resultsComponent={SearchResultsTableComponent}
        onFetchResults={async (query) => adminClient().adminItemsSearchGet({
            query
        })}
    />
});