import InternalLink from "@components/InternalLink";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { EsItemResponse } from "@reshopper/admin-client";
import { toDanishDateFormat } from "@utils/miscellaneous";
interface Props {
  searchResults: EsItemResponse[];
}

export default function SearchResultsTableComponent(props: Props) {

  function renderRow(item: EsItemResponse) {
    return <>
      <TableCell>
        {item.id && 
          <InternalLink href={"/admin/items/details/?id=" + encodeURIComponent(item.id)}>
            {item.brandOrTitle}
          </InternalLink>}
      </TableCell>
      <TableCell>{item.category}</TableCell>
      <TableCell>{toDanishDateFormat(item.createdUtc)}</TableCell>
      <TableCell>{item.id}</TableCell>
      <TableCell>
        <InternalLink href={"/admin/users/details/?id=" + encodeURIComponent(item.userId)}>
          User
        </InternalLink>
      </TableCell>
    </>;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Desciption</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Id</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.searchResults?.map((item, index) => (
              <TableRow
                key={index.toString()}
                style={{
                  background: index % 2 ?
                    "#F9F9F9" :
                    "white"
                }}
              >
                {renderRow(item)}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>

  )
}

